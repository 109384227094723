import styled from 'styled-components';

export const CssBtnWrapper = styled.div.attrs((props) => ({
  className: props.className
}))`
  & .storybook-button {
    border: 0;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
  }
  & .storybook-button--primary {
    color: white;
    background-color: #000f9f;
  }
  & .storybook-button--secondary {
    color: #333;
    background-color: transparent;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;
  }
  & .storybook-button--small {
    font-size: 12px;
    padding: 10px 16px;
  }
  & .storybook-button--medium {
    font-size: 14px;
    padding: 11px 20px;
  }
  & .storybook-button--large {
    font-size: 16px;
    padding: 12px 24px;
  }
`;
