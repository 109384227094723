import React, { createContext, useState, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { LightTheme } from './themes/LightTheme'
import { DarkTheme } from './themes/DarkTheme'

export const ThemeContext = createContext(null)

export const ApdTheming = (props) => {
  const [activeTheme, setActiveTheme] = useState('LightTheme')
  const [theme, setTheme] = useState(LightTheme)
 

  useEffect(() => {
    switch (activeTheme) {
      case 'DarkTheme':
        setTheme(DarkTheme)
        break

      default:
        setTheme(LightTheme)
        break
    }
  }, [activeTheme])

  return (
    <ThemeContext.Provider value={{ activeTheme: activeTheme, setActiveTheme: setActiveTheme }}>
      <ThemeProvider theme={theme}>
        {props.children}
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}
