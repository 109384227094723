import React, { useRef, useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import 'moment/min/locales';

import {
  CssMenuContainer,
  CssDropdownLabel,
  CssDropdownTitle,
  CssMainWrapper,
  CssActionWrapper
} from './DropdownDatepicker-style';

import { useDetectOutsideClick } from './useDetectOutsideClick';

import { DayPickerRangeController } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { StyledDatePickerWrapper } from './Calendar-style';

import { Button } from './btnComponent/Button';

// icons
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

const formatDate = (date) => moment(date).locale('pt').format('DD MMM YYYY');

moment.locale('pt');

export const DropdownDatepicker = ({
  dropdownTitle,
  labelInfo,
  dateEnd,
  dateStart,
  dateBigBang,
  isMobile = false,
  onEvent
}) => {
  const dropdownRef = useRef(null);
  const inputRef = useRef();
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

  const [dates, setDates] = useState({
    startDate: dateStart,
    endDate: dateEnd
  });

  const [pickaDate, setPickaDate] = useState({
    startDate: dateStart,
    endDate: dateEnd
  });

  const defaultFocusedInput = 'startDate';
  const [focusedInput, setFocusedInput] = useState(defaultFocusedInput);

  const onClick = () => setIsActive(!isActive);

  const onSelectDates = () => {
    setIsActive(false);
    setDates(pickaDate);
  };

  const onSelectReset = () => {
    setIsActive(false);
    setPickaDate(dates);
  };

  useEffect(() => {
    dates && onEvent(dates);
  }, [dates]);

  return (
    <Fragment>
      <CssMenuContainer ref={dropdownRef}>
        <CssDropdownLabel>{labelInfo}</CssDropdownLabel>
        <button onClick={onClick} className='menu-trigger'>
          <p className='menu-title'>
            {`${formatDate(dates.startDate)} - ${formatDate(dates.endDate)}`}
          </p>
          <p className='menu-arrow'>
            {isActive ? <ArrowDropUp /> : <ArrowDropDown />}
          </p>
        </button>

        <div className={`menu ${isActive ? 'active' : 'inactive'}`}>
          <CssMainWrapper>
            <StyledDatePickerWrapper>
              {dropdownTitle && (
                <CssDropdownTitle>{dropdownTitle}</CssDropdownTitle>
              )}

              <DayPickerRangeController
                ref={inputRef}
                hideKeyboardShortcutsPanel
                daySize={33}
                minimumNights={0}
                orientation={isMobile ? 'vertical' : undefined}
                // eslint-disable-next-line react/jsx-boolean-value
                moveRangeOnFirstSelection={true}
                startDate={pickaDate.startDate}
                endDate={pickaDate.endDate}
                onDatesChange={(dates) => setPickaDate(dates)}
                focusedInput={focusedInput || defaultFocusedInput}
                onFocusChange={(focucedInput) => setFocusedInput(focucedInput)}
                numberOfMonths={2}
                renderMonthElement={({ month }) =>
                  moment(month).locale('pt').format('MMMM YYYY')
                }
                renderDayContents={(day) => {
                  // Change week day with custom day array
                  day._locale._weekdaysMin = [
                    'dom',
                    'seg',
                    'ter',
                    'qua',
                    'qui',
                    'sex',
                    'sab'
                  ];
                  return day.format('D');
                }}
                displayFormat={() =>
                  moment.localeData('pt').longDateFormat('L')
                }
                isOutsideRange={(day) =>
                  day.isAfter(moment(new Date())) ||
                  (dateBigBang && day.isBefore(dateBigBang))
                }
              />
            </StyledDatePickerWrapper>

            <CssActionWrapper isMobile={isMobile}>
              <Button size='small' label='Cancelar' onClick={onSelectReset} />
              <Button
                size='small'
                label='Aplicar'
                onClick={onSelectDates}
                primary
              />
            </CssActionWrapper>
          </CssMainWrapper>
        </div>
      </CssMenuContainer>
    </Fragment>
  );
};

DropdownDatepicker.propTypes = {
  labelInfo: PropTypes.string,
  dropdownTitle: PropTypes.string,
  onEvent: PropTypes.func
};

DropdownDatepicker.defaultProps = {};
