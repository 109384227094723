import styled from 'styled-components';

export const StyledDatePickerWrapper = styled.div`
  font-family: 'Roboto';

  // NOTE: the order of these styles DO matter
  // Will edit everything selected including everything between a range of dates
  & .CalendarDay__selected_span {
    background: ${(props) => props.theme.brandToken.colors.gray_2}; //background
    color: ${(props) => props.theme.colors.text.default_1}; //text
    border: 1px solid ${(props) => props.theme.brandToken.colors.gray_3}; //default styles include a border
  }
  & .CalendarDay__selected_span:hover {
    background: ${(props) => props.theme.brandToken.colors.gray_3}; //background
    border: 1px solid ${(props) => props.theme.brandToken.colors.gray_3};
    color: ${(props) => props.theme.colors.text.default_1};
    /* box-shadow: inset 0px 0px 3px ${(props) =>
      props.theme.brandToken.colors.gray_3}; */
  }

  // Will edit selected date or the endpoints of a range of dates
  & .CalendarDay__selected {
    background: ${(props) => props.theme.brandToken.colors.apd_t1};
    color: white;
    border: 1px solid ${(props) => props.theme.brandToken.colors.apd_t1};
  }

  // Will edit when hovered over. _span style also has this property

  & .CalendarDay__selected:active {
    background: ${(props) => props.theme.brandToken.colors.apd_m};
    color: ${(props) => props.theme.brandToken.colors.base_white}; //text
  }

  & .CalendarDay__selected:hover {
    background: ${(props) => props.theme.brandToken.colors.apd_m};
    border: 1px solid ${(props) => props.theme.brandToken.colors.apd_m};
    color: ${(props) => props.theme.brandToken.colors.base_white}; //text
  }

  // Will edit when the second date (end date) in a range of dates
  // is not yet selected. Edits the dates between your mouse and said date
  & .CalendarDay__hovered_span,
  & .CalendarDay__hovered_span:hover {
    background: ${(props) => props.theme.brandToken.colors.gray_2}; //background
    color: ${(props) => props.theme.colors.text.default_1}; //text
    border: 1px solid ${(props) => props.theme.brandToken.colors.gray_3}; //default styles include a border
  }
`;
